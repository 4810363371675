<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Name Input -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="nameInput">Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="nameInput"
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name"
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true, min: 1, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Amount -->
          <b-col cols="12">
            <b-form-group id="input-amount">
              <label for="emailInput">Amount: <span class="text-danger">*</span></label>
              <b-form-input
                id="amountInput"
                name="amount"
                v-model="editedItem.amount"
                type="number"
                aria-describedby="input-amount"
                data-vv-as="amount"
                :class="{ input: true, 'is-danger': errors.has('amount') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('amount')" class="is-danger-text position-relative">{{
                errors.first('amount')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Note -->
          <b-col cols="12">
            <b-form-group id="input-note">
              <label for="noteInput">Note: <span class="text-danger">*</span></label>
              <b-form-input
                id="noteInput"
                name="note"
                v-model="editedItem.note"
                type="text"
                aria-describedby="input-note"
                data-vv-as="note"
                :class="{ input: true, 'is-danger': errors.has('note') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('note')" class="is-danger-text position-relative">{{
                errors.first('note')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Max use -->
          <b-col cols="12">
            <b-form-group id="input-max_use">
              <label for="max_useInput">Max Use:</label>
              <b-form-input name="max_use" v-model="editedItem.max_use"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Coupon Type List</h4>
      </b-col>

      <!-- Filter Name -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Name" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search name..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>

      <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'Coupon Type',
  components: {},
  data() {
    return {
      search: '',
      isBusy: false,
      filter_status: null,
      filter_name: '',
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Name', key: 'name' },
        { text: 'Amount', key: 'amount' },
        { text: 'Note', key: 'note' },
        { text: 'Num Of Used', key: 'num_of_used' },
        { text: 'Max Use', key: 'max_use' },
        { text: 'Slug', key: 'slug' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allCouponTypeList: [],
      selectedCouponType: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        amount: '',
        note: '',
        max_use: '',
        slug: '',
        status: ''
      },
      dialog: false,
      editedIndex: -1,
      editorConfig: {
        language: 'de'
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        !this.editedItem.name ||
        this.errors.has('name') ||
        !this.editedItem.amount ||
        this.errors.has('amount') ||
        !this.editedItem.note ||
        this.errors.has('note') ||
        this.filter_status === null ||
        this.errors.has('Status')
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Coupont Type List' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const couponType = ApiService.get(
        'coupon-type',
        `?name=${this.filter_name}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      axios
        .all([couponType])
        .then(
          axios.spread((...response) => {
            const couponTypeRes = response[0];
            this.items = couponTypeRes.data.data.couponTypeList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = couponTypeRes.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.dialog = true;
      this.modalTitle = 'Add Coupon Type';
    },
    editItem: function (item) {
      this.dialog = true;
      this.modalTitle = 'Update Coupon Type';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedCouponType = item.role_id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`coupon-type/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Coupont type deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        name: '',
        amount: '',
        note: '',
        max_use: '',
        slug: '',
        status: ''
      };
      this.editedIndex = -1;
      this.filter_name = null;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      this.editedItem.status = this.filter_status;
      let couponData = {
        name: this.editedItem.name,
        amount: +parseFloat(this.editedItem.amount).toFixed(2),
        note: this.editedItem.note,
        max_use: isNaN(this.editedItem.max_use) ? 0 : parseInt(this.editedItem.max_use),
        status: this.editedItem.status
      };
      if (this.editedIndex > -1) {
        // Update coupon type
        axios({
          method: 'PUT',
          url: `coupon-type/${this.items[this.editedIndex].id}`,
          data: couponData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Coupon type updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast(`errors`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        // Add coupon type
        axios({
          method: 'POST',
          url: 'coupon-type',
          data: couponData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data
              });
              this.close();
              this.$bvToast.toast('Coupon type added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              // this.errors.push(response.data.data);
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
